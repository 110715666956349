import { Typography } from "@material-ui/core"
import { GeelyCarModelResponse } from "api"
import BottomButtons from "common/BottomButtons"
import BottomText from "common/BottomText"
import { Desktop, Mobile } from "common/MediaQueries"
import NextImage from "common/NextImage"
import { formatCurrency } from "common/utils/formatters"
import { mobileQuery } from "common/utils/mediaQueryCss"
import { useInView } from "react-intersection-observer"
import styled, { css } from "styled-components"
import CarDetails from "./CarDetails"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-top: 41px;
  }
`

const StyledBackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 360px;
  object-fit: cover;
  top: 0;
  left: 0;
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 80%;
    height: 100%;
    left: 10%;
  }
`
const StyledMainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 100%;
  }
`

const StyledH1 = styled.h1<{ $inView: boolean }>`
  margin: 0;
  transition: all 300ms ease-in-out;
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-bottom: 27px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: 60px;
    ${(p) =>
      !p.$inView
        ? css`
            transform: translateY(-40px);
            opacity: 0;
          `
        : css`
            transform: translateY(0);
            opacity: 1;
          `};
  }
`
const StyledHeading = styled(Typography).attrs({ component: "span" })`
  display: block;
  text-align: center;
  font-family: "Geely";
  text-transform: uppercase;
  margin: 90px 100px 12px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 20px;
  ${(p) => p.theme.breakpoints.down("sm")} {
    letter-spacing: 9px;
    margin-top: 80px;

    @media only screen and (max-height: 650px) {
      font-size: 16px;
    }
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    margin: 0;
    margin-bottom: 18px;
    font-size: 42px;
    @media only screen and (max-height: 750px) {
      margin-top: -10px;
    }
  }
`
const StyledSubheading = styled(Typography).attrs({ component: "span" })`
  display: block;
  font-family: "Geely";
  white-space: nowrap;
  font-weight: 400;
  margin-top: 0;
  background: transparent
    linear-gradient(
      90deg,
      ${(p) => p.theme.colors.mainBlue} 0%,
      ${(p) => p.theme.colors.mainPurple} 100%
    )
    0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 0;
  line-height: 1;
  ${(p) => p.theme.breakpoints.down("sm")} {
    font-size: 29px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 92px;
    @media only screen and (max-height: 750px) {
      margin-top: -10px;
    }
    @media only screen and (max-height: 650px) {
      margin-top: -0px;
    }
  }
`

const StyledCarImage = styled.div<{ $inView: boolean }>`
  aspect-ratio: 1600 / 800;
  height: auto;
  width: 150%;
  z-index: 1;
  position: relative;

  ${(p) => p.theme.breakpoints.up("md")} {
    width: calc(1600px * 0.9);
    height: auto;
    flex-shrink: 0;
    margin-top: -320px;
    margin-bottom: -215px;
  }

  ${(p) => p.theme.breakpoints.down("md")} {
    width: calc(1400px * 0.9);
    flex-shrink: 0;
    margin-top: -300px;
    margin-bottom: -150px;
  }

  @media only screen and (max-height: 700px) and (min-width: 960px) {
    width: calc(1100px * 0.9);
    margin-top: -250px;
    margin-bottom: -150px;
  }

  @media only screen and (max-height: 600px) and (min-width: 960px) {
    width: calc(900px * 0.9);
    margin-top: -225px;
    margin-bottom: -125px;
  }

  @media only screen and (min-height: 500px) and (max-height: 550px) and (min-width: 960px) {
    width: calc(800px * 0.9);
    margin-top: -220px;
    margin-bottom: -100px;
  }

  ${mobileQuery`
  margin-top: -84px;
  width:  calc(600px * 0.9);
  height: 260px;
  margin-bottom: -40px;
  `}
`
const StyledPrice = styled.div<{ $inView: boolean }>`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  transition: transform 300ms ease-in-out;
  z-index: 2;

  ${(p) => p.theme.breakpoints.down("sm")} {
    @media only screen and (max-height: 650px) {
      margin-bottom: 5px;
    }
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-bottom: 0;
    margin-right: 850px;
    font-size: 20px;
    ${(p) =>
      !p.$inView
        ? `    transform: translateX(0px) translateY(-60px);`
        : `transform: translateX(0) translateY(0)`};
  }
`
const StyledFrom = styled.span`
  ${(p) => p.theme.breakpoints.up("md")} {
    display: block;
    margin-bottom: -12px;
  }
`
const StyledSpanPrice = styled.span`
  font-size: 32px;
  letter-spacing: -2px;
  font-weight: 300;
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 50px;
    margin-top: -5px;
    font-weight: 300;
  }
`
const StyledSpanShekel = styled.span`
  font-size: 20px;
`
const StyledBottomButtons = styled(BottomButtons)`
  padding-bottom: 0;
  margin-bottom: auto;
`
const StyledBottomTextWrap = styled.div`
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
`
const Hero = ({ data }: { data: GeelyCarModelResponse }) => {
  const { ref, inView } = useInView()
  return (
    <StyledContainer ref={ref}>
      <StyledBackgroundImage>
        <NextImage
          image={data.backgroundImage.imageUrl}
          imageSizes={["100vw", "80vw"]}
          priority
          alt={data.backgroundImage.alt}
        />
      </StyledBackgroundImage>
      <StyledMainContainer>
        {/* wrap both headings in h1 and add an invisible text for the subheading*/}
        <StyledH1 $inView={inView}>
          <Typography variant="srOnly">{data.secretMainTitle}</Typography>
          <StyledHeading>{data.mainTitle}</StyledHeading>
          <StyledSubheading>{data.subTitle}</StyledSubheading>
        </StyledH1>
        <Desktop>
          <StyledPrice id="heroPrice" $inView={inView}>
            <StyledFrom>החל מ-</StyledFrom>

            <StyledSpanShekel>₪</StyledSpanShekel>
            <StyledSpanPrice>{formatCurrency(data.price)}</StyledSpanPrice>
          </StyledPrice>
        </Desktop>
        <StyledCarImage id="heroCarModel" $inView={inView}>
          <NextImage
            image={data.image.imageUrl}
            imageSizes={["150vw", "90vw"]}
            priority
            alt={data.image.alt}
          />
        </StyledCarImage>
        <Mobile aboveTheFold>
          <StyledPrice $inView={inView}>
            החל מ- <StyledSpanShekel>₪</StyledSpanShekel>
            <StyledSpanPrice>{formatCurrency(data.price)}</StyledSpanPrice>
          </StyledPrice>
        </Mobile>
        <CarDetails details={data.properties} isDetailing={false} />
        <Desktop>
          <StyledBottomButtons refer={true} />
        </Desktop>

        <StyledBottomTextWrap>
          <BottomText
            align="center"
            textAlign="center"
            inputText={data.carLegalTerms}
          />
        </StyledBottomTextWrap>
      </StyledMainContainer>
    </StyledContainer>
  )
}

export default Hero
