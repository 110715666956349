import { Button, IconButton, Typography } from "@material-ui/core"
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined"
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined"
import DoubleArrowRoundedIcon from "@material-ui/icons/DoubleArrowRounded"
import { GeelyCarModelResponse } from "api"
import BottomButtons from "common/BottomButtons"
import BottomText from "common/BottomText"
import { Desktop, Mobile } from "common/MediaQueries"
import NextImage from "common/NextImage"
import { useScrollSections } from "common/ScrollSection"
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

const StyledBackground = styled.div`
  background-color: ${(p) => p.theme.colors.paleGrey};
  overflow: hidden;
  height: 100%;
  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: row-reverse;
  }
`

const StyledSwiper = styled(Swiper)`
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 60%;
  }
  .swiper-slide {
    overflow: hidden;
    > img {
      transform: translateY(-40px) scale(1.3);
    }
    &.swiper-slide-active.inView {
      > img {
        transform: translateY(0) scale(1);
      }
    }
  }
`
const SwiperImage = styled.div<{ $inView: boolean }>`
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: calc(100% * 270 / 360);
  transition: transform 300ms ease-in-out;
  /* ${(p) =>
    !p.$inView
      ? `transform: translateY(-40px) scale(1.3)`
      : ` transform: translateY(0) scale(1)`}; */
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 100%;
    padding-bottom: 0;
    object-fit: cover;
  }
`
const StyledFadeEffect = styled.div`
  width: 100%;
  height: 110px;
  background: linear-gradient(
    to bottom,
    rgba(245, 247, 248, 0),
    ${(p) => p.theme.colors.paleGrey}
  );
  position: absolute;
  bottom: 0;
`
const StyledInfoAndDis = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-left: 20px;
    margin-right: 20px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 750px;
    justify-content: center;
  }
`
const StyledDesignInfo = styled.div<{ $inView: boolean }>`
  background-color: ${(p) => p.theme.colors.white};
  width: 100%;
  height: 240px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 15px 27px 15px;
  color: ${(p) => p.theme.colors.midnight};
  ${(p) => p.theme.breakpoints.down("sm")} {
    transition: transform 300ms ease-in-out;
    ${(p) =>
      !p.$inView ? `transform: translateX(120%)` : `transform: translateX(0)`};
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    width: auto;
    height: 380px;
    margin: auto 0px auto 50px;
    padding: 50px 100px 50px 60px;
  }
`
const StyledNavigationHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  margin-bottom: 18px;
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-bottom: 40px;
  }
`
const StyledPagination = styled.div`
  direction: ltr;
  font-size: 18px;

  span {
    font-weight: bold;
  }
`
const StyledLine = styled.div`
  border: 1px solid ${(p) => p.theme.colors.cloudyBlue};
  width: 130px;
  height: 1px;
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-right: 50px;
    flex-grow: 1;
  }
`
const StyledNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70px;
  margin-right: -15px;
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 120px;
  }
`

const StyledTitle = styled(Typography).attrs({
  variant: "h2",
  component: "h2",
})`
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 10px;
  margin-bottom: 13px;
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 38px;
    margin-bottom: 14px;
  }
`
const StyledDescription = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 50px;
  }
`
const StyledDesktopInfoFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: auto;
`

const StyledDoubleArrow = styled(DoubleArrowRoundedIcon)`
  transform: scaleX(-1);
  font-size: x-large;
`
const StyledSkip = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledSkipText = styled.div`
  font-size: 18px;
  font-weight: bold;
`
const StyledBottomTextWrap = styled.div`
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-bottom: 28px;
    margin-left: 50px;
    margin-right: 100px;
  }
`
const StyledBottomButtonsWrap = styled.div`
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-right: 80px;
    padding-top: 0px;
  }
`
const Design = ({ data }: { data: GeelyCarModelResponse }) => {
  const { sections } = useScrollSections()
  const [active, setActive] = useState(0)
  const [swiperController, setSwiperController] = useState<SwiperCore>(null)
  const { ref, inView } = useInView({
    threshold: 0.3,
  })
  if (!data.styleComponents.length) {
    return null
  }

  return (
    <StyledBackground ref={ref}>
      <StyledSwiper
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        onSlideChange={(swiper) => {
          setActive(swiper.activeIndex)
        }}
        onSwiper={setSwiperController}
      >
        {data.styleComponents.map((design, index) => (
          <SwiperSlide key={index} className={inView ? "inView" : ""}>
            <SwiperImage $inView={inView}>
              <NextImage
                image={design?.image.imageUrl}
                imageSizes={["100vw", "50vw"]}
                alt={design?.image?.alt}
                priority={index === 0}
              />
            </SwiperImage>
            <Mobile>
              <StyledFadeEffect />
            </Mobile>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <StyledInfoAndDis>
        <StyledDesignInfo $inView={inView}>
          <StyledNavigationHeader>
            <StyledPagination>
              <span> {active + 1} </span>/ {data.styleComponents.length}
            </StyledPagination>
            <StyledLine></StyledLine>
            <Mobile>
              <StyledNavigation>
                {/* make arrow color disabled if there is no previous */}
                <ArrowForwardIosOutlinedIcon
                  aria-label="כפתור המשך"
                  color={active === 0 ? "disabled" : "primary"}
                  onClick={() => swiperController?.slidePrev()}
                />
                <ArrowBackIosOutlinedIcon
                  aria-label="כפתור חזור"
                  color={
                    active === data.styleComponents.length - 1
                      ? "disabled"
                      : "primary"
                  }
                  onClick={() => swiperController?.slideNext()}
                />
              </StyledNavigation>
            </Mobile>
          </StyledNavigationHeader>
          <StyledTitle>{data.styleComponents[active].mainTitle}</StyledTitle>
          <StyledDescription>
            {data.styleComponents[active].subTitle}
          </StyledDescription>
          <Desktop>
            <StyledDesktopInfoFooter>
              <StyledNavigation>
                {/* make arrow color disabled if there is no previous */}
                <IconButton
                  aria-label={`${
                    active === 0 ? `כפתור המשך לא זמין` : `כפתור המשך`
                  } ,שקופית ${active + 1} מתוך ${data.styleComponents.length}`}
                  onClick={() => swiperController?.slidePrev()}
                >
                  <ArrowForwardIosOutlinedIcon
                    color={active === 0 ? "disabled" : "primary"}
                  />
                </IconButton>
                <IconButton
                  aria-label={`${
                    active === data.styleComponents.length - 1
                      ? `כפתור אחור לא זמין`
                      : `כפתור אחור`
                  } ,שקופית ${active + 1} מתוך ${data.styleComponents.length}`}
                  onClick={() => swiperController?.slideNext()}
                >
                  <ArrowBackIosOutlinedIcon
                    color={
                      active === data.styleComponents.length - 1
                        ? "disabled"
                        : "primary"
                    }
                  />
                </IconButton>
              </StyledNavigation>
              <StyledSkip
                aria-label="כפתור דלג להמשך בעמוד"
                onClick={() => {
                  sections[2].onClick()
                }}
              >
                <StyledSkipText>דלג</StyledSkipText>
                <StyledDoubleArrow />
              </StyledSkip>
            </StyledDesktopInfoFooter>
          </Desktop>
        </StyledDesignInfo>
        <Desktop>
          <StyledBottomButtonsWrap>
            <BottomButtons align="flex-start" />
          </StyledBottomButtonsWrap>
        </Desktop>
        <StyledBottomTextWrap>
          <BottomText
            align="flex-start"
            textAlign="start"
            inputText={data.styleLegalTerms}
          />
        </StyledBottomTextWrap>
      </StyledInfoAndDis>
    </StyledBackground>
  )
}

export default Design
