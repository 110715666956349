import { css } from "styled-components"

export const mobileQuery = (styles) => css`
  ${(p) => p.theme.breakpoints.down("sm")} {
    ${styles}
  }
`

export const desktopQuery = (styles) => css`
  ${(p) => p.theme.breakpoints.up("md")} {
    ${styles}
  }
`
