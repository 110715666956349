import { Typography } from "@material-ui/core"
import styled from "styled-components"

const StyledTextContainer = styled.div<{ $align: string }>`
  display: flex;
  /* position: absolute; */
  /* bottom: 0; */
  /* padding: 0 10px 30px 10px; */
  width: 100%;
  ${(p) => p.theme.breakpoints.down("sm")} {
    justify-content: center;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    /* padding: 0 100px 10px 100px; */
    justify-content: ${(p) => p.$align};
  }
`

const StyledText = styled(Typography)<{ $width: string; $textAlign: string }>`
  font-size: 10px;
  color: ${(p) => p.theme.colors.greyishBrown};
  text-align: ${(p) => p.$textAlign};
  width: ${(p) => p.$width};
  line-height: 1.4;
  ${(p) => p.theme.breakpoints.up("md")} {
    text-align: ${(p) => p.$textAlign};
  }
`
type BottomTextProps = {
  align: string
  width?: string
  textAlign?: string
  inputText: string
}
const BottomText = ({
  align,
  width,
  textAlign,
  inputText,
}: BottomTextProps) => {
  return (
    <StyledTextContainer $align={align}>
      <StyledText $width={width} $textAlign={textAlign}>
        {inputText}
      </StyledText>
    </StyledTextContainer>
  )
}

export default BottomText
