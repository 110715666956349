import { GeelyCarModelsProperties } from "api"
import React from "react"
import styled, { keyframes } from "styled-components"

const headingTransition = keyframes`
  from {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
  }
`
const StyledDetails = styled.div<{ $isDetailing: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  background-color: transparent;

  padding: 0 15px;
  z-index: 2;

  ${(p) => p.theme.breakpoints.up("md")} {
    width: ${(p) => (p.$isDetailing ? "100%" : "50%")};
    margin-top: 30px;
    animation: ${headingTransition} 300ms ease-in-out;
  }
`
const StyledDetailElement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`
const StyledDivider = styled.div`
  border-left: 1px solid ${(p) => p.theme.colors.cloudyBlue};
  height: 55px;
`
const StyledSpanDetail = styled.span<{ $isDetailing: boolean }>`
  font-size: ${(p) => (p.$isDetailing ? "20px" : "21px")};
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: ${(p) => (p.$isDetailing ? "20px" : "36px")};
  }
`
const StyledDetailText = styled.span<{ $isDetailing: boolean }>`
  font-size: ${(p) => (p.$isDetailing ? "10px" : "12px")};
  text-align: center;
  color: ${(p) => p.theme.colors.greyishBrown};
  font-weight: normal;
`
type CarDetailProps = {
  details: GeelyCarModelsProperties[]
  isDetailing: boolean
}
const CarDetails = ({ details, isDetailing }: CarDetailProps) => {
  return (
    <StyledDetails $isDetailing={isDetailing}>
      {details?.map((detail, index) => (
        <React.Fragment key={index}>
          {index > 0 && <StyledDivider />}
          <StyledDetailElement>
            <StyledSpanDetail $isDetailing={isDetailing}>
              {detail.preValue && (
                <StyledDetailText $isDetailing={isDetailing}>
                  {detail.preValue}{" "}
                </StyledDetailText>
              )}
              {detail.value}
              {detail.postValue && (
                <StyledDetailText $isDetailing={isDetailing}>
                  {" "}
                  {detail.postValue}
                </StyledDetailText>
              )}
            </StyledSpanDetail>
            <StyledDetailText $isDetailing={isDetailing}>
              {detail.name}
            </StyledDetailText>
          </StyledDetailElement>
        </React.Fragment>
      ))}
    </StyledDetails>
  )
}

export default CarDetails
