import { GeelyCarModelResponse, getGeelyModelsId, MainBanner } from "api"
import { extractData } from "api/extractData"
import BottomButtons from "common/BottomButtons"
import { Mobile } from "common/MediaQueries"
import { getModelNameHebrew } from "common/modelConsts"
import { ScrollingProvider, Section } from "common/ScrollSection"
import { getBannerByKey, SeoBanner } from "common/SeoBanner"
import { seoKeys } from "common/SeoMetaData"
import StaticMenu, { STICKY_OFFSET } from "common/StaticMenu"
import Design from "features/sections/Design/Design"
import Hero from "features/sections/Hero/Hero"
import Footer from "layout/Footer"
import { DESKTOP_HEIGHT, MOBILE_HEIGHT } from "layout/Header"
import { AllMenus, fetchMenus } from "layout/LayoutContext"
import { NextSeo } from "next-seo"
import dynamic from "next/dynamic"
import Head from "next/head"
import { wrapper } from "store"
import styled, { css } from "styled-components"
import "swiper/css/bundle"
import theme from "theme/theme"

const MapsSection = dynamic(() => import("features/sections/Map/MapsSection"))
const SavingsCalculator = dynamic(
  () => import("features/sections/SavingsCalculator/SavingsCalculator")
)
const Safety = dynamic(() => import("features/sections/Safety/Safety"))
const SEMSystem = dynamic(() => import("features/sections/SEMSystem/SEMSystem"))
const Gallery = dynamic(() => import("features/sections/Gallery/Gallery"))
const PeaceOfMind = dynamic(
  () => import("features/sections/PeaceOfMind/PeaceOfMind")
)
const SafetyPollution = dynamic(
  () => import("features/sections/SafetyPollution/SafetyPollution")
)

const PURCHASE_MODEL_ID = process.env.PURCHASE_MODEL_ID

const title = `רכב חשמלי | חשבו רחוק יותר חשבו ${getModelNameHebrew()}  - ג'ילי - Geely`
const description = `ענקית הרכב החשמלי ג'ילי כעת בישראל ומציגה את דגם ה${getModelNameHebrew()} בעל טווח הנסיעה העצום והטעינה המהירה. כנסו עכשיו להתרשם מהרכב ולקבוע נסיעת מבחן.`

export const getStaticProps = wrapper.getStaticProps(() => async () => {
  try {
    const body = await getGeelyModelsId(PURCHASE_MODEL_ID).then(extractData)

    const bannerSeo = await getBannerByKey(seoKeys.Homepage)

    const menus = await fetchMenus()

    return {
      props: { data: body, bannerSeo, menus },
      revalidate: 60,
    }
  } catch (error) {
    return {
      props: { data: null, bannerSeo: null, menus: null },
      revalidate: 60,
    }
  }
})

type HomeProps = {
  data: GeelyCarModelResponse
  bannerSeo: MainBanner
  menus: AllMenus
}

const StyledHero = styled(Section)<{
  $mobileMinHeight?: string
}>`
  padding-top: ${MOBILE_HEIGHT - 41}px;
  min-height: ${(p) =>
    p.$mobileMinHeight
      ? p.$mobileMinHeight
      : css`calc(100vh - ${MOBILE_HEIGHT}px)`};
  ${(p) => p.theme.breakpoints.up("md")} {
    padding-top: ${DESKTOP_HEIGHT}px;
    min-height: 100vh;
    height: auto;
  }
`
const StyledSection = styled(Section)<{
  $mobileMinHeight?: string
  $desktopHeight?: string
}>`
  min-height: ${(p) =>
    p.$mobileMinHeight
      ? p.$mobileMinHeight
      : css`calc(100vh - ${MOBILE_HEIGHT}px)`};
  ${(p) => p.theme.breakpoints.up("md")} {
    min-height: ${(p) => (p.$desktopHeight ? p.$desktopHeight : "600px")};
    height: ${(p) =>
      p.$desktopHeight
        ? p.$desktopHeight
        : css`calc(100vh - ${DESKTOP_HEIGHT}px)`};
  }
`

const Home = ({ data, bannerSeo }: HomeProps) => {
  if (!data) return null

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title,
          description,
          images: [
            {
              url: "https://geely.co.il/saving_image@3x.jpg",
              width: 1080,
              height: 675,
            },
            {
              url: "https://geely.co.il/saving_image.jpg",
              width: 256,
              height: 165,
            },
          ],
        }}
      />

      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Organization",
              name: "Geely",
              url: "https://geely.co.il/",
              logo: {
                "@type": "ImageObject",
                url: "https://geely.co.il/geely-think-further.svg",
              },
              sameAs:
                "https://he.wikipedia.org/wiki/%D7%92%27%D7%99%D7%9C%D7%99",
            }),
          }}
        ></script>
      </Head>

      <ScrollingProvider offset={STICKY_OFFSET}>
        <StaticMenu />
        <SeoBanner bannerSeoData={bannerSeo} pageName={seoKeys.Homepage} />
        <StyledHero
          id="hero"
          title="ראשי"
          activeColor={theme.colors.darkNavyBlue}
          inactiveColor={theme.colors.cloudyBlue}
          $mobileMinHeight="auto"
          aboveTheFold
        >
          <Hero data={data} />
        </StyledHero>
        <StyledSection
          id="design"
          title="עיצוב"
          activeColor={theme.colors.darkNavyBlue}
          inactiveColor={theme.colors.cloudyBlue}
          $mobileMinHeight="auto"
          aboveTheFold
        >
          <Design data={data} />
        </StyledSection>
        <StyledSection
          id="maps"
          title="סוללה"
          activeColor={theme.colors.darkNavyBlue}
          inactiveColor={theme.colors.cloudyBlue}
          $desktopHeight="auto"
        >
          <MapsSection data={data} />
        </StyledSection>
        <StyledSection
          id="calculator"
          title="מחשבון חסכון"
          activeColor={theme.colors.darkNavyBlue}
          inactiveColor={theme.colors.cloudyBlue}
        >
          <SavingsCalculator data={data} isHomePage />
        </StyledSection>
        <StyledSection
          id="safety"
          title="בטיחות"
          activeColor={theme.colors.darkNavyBlue}
          inactiveColor={theme.colors.cloudyBlue}
          $mobileMinHeight="auto"
        >
          <Safety data={data} />
        </StyledSection>
        <StyledSection
          id="Explore"
          title="EXPLORE"
          activeColor={theme.colors.darkNavyBlue}
          inactiveColor={theme.colors.cloudyBlue}
          $mobileMinHeight="500px"
        >
          <SEMSystem data={data} />
        </StyledSection>
        <StyledSection
          id="gallery"
          title="גלרייה"
          activeColor={theme.colors.mainBlue}
          inactiveColor={theme.colors.cloudyBlue}
          $mobileMinHeight="auto"
        >
          <Gallery data={data} />
        </StyledSection>
        <StyledSection
          id="peace-of-mind"
          title="שקט נפשי"
          activeColor={theme.colors.darkNavyBlue}
          inactiveColor={theme.colors.cloudyBlue}
          $mobileMinHeight="auto"
          $desktopHeight="auto"
        >
          <PeaceOfMind data={data} />
        </StyledSection>
        <StyledSection
          id="safety-pollution"
          title="זיהום אוויר"
          activeColor={theme.colors.darkNavyBlue}
          inactiveColor={theme.colors.cloudyBlue}
          $mobileMinHeight="auto"
          $desktopHeight="auto"
        >
          <SafetyPollution data={data} />
        </StyledSection>
        <Footer isPurchasedPage={false} menu={null} />
        <Mobile aboveTheFold>
          <BottomButtons refer={true} fixed={true} />
        </Mobile>
      </ScrollingProvider>
    </>
  )
}

export default Home
