import { Tab, Tabs, Typography } from "@material-ui/core"
import {
  ScrollingProvider,
  Section,
  useScrollSections,
} from "common/ScrollSection"
import { MOBILE_HEIGHT } from "layout/Header"
import { useLayoutContext } from "layout/LayoutContext"
import { useEffect } from "react"
import styled from "styled-components"
import { Desktop } from "./MediaQueries"
import { trackVehiclePageView } from "./analytics"
export { ScrollingProvider, Section }

const MENU_HEIGHT = 41
export const STICKY_OFFSET = -(MOBILE_HEIGHT + MENU_HEIGHT)

const Styled = {
  StaticMenu: styled(Tabs).attrs({
    TabIndicatorProps: { children: <span className="MuiTabs-indicatorSpan" /> },
  })`
    position: fixed;
    top: ${MOBILE_HEIGHT}px;
    background: ${(p) => p.theme.colors.white};
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-height: ${MENU_HEIGHT}px;
    justify-content: center;

    ${(p) => p.theme.breakpoints.up("md")} {
      display: none;
    }

    .MuiTab-root {
      min-height: ${MENU_HEIGHT}px;
      color: ${(p) => p.theme.colors.greyishBrown};
    }

    .MuiTab-wrapper {
      font-weight: 500;
      font-size: 12px;
    }
    .MuiTabs-indicator {
      top: 0px;
      ${({ theme }) => theme.breakpoints.down("sm")} {
        display: flex;
        justify-content: center;
        background-color: transparent;
      }
    }

    .MuiTabs-indicatorSpan {
      ${({ theme }) => theme.breakpoints.down("sm")} {
        max-width: 30px;
        width: 100%;
        background-color: ${(p) => p.theme.colors.mainBlue};
      }
    }
  `,
  Tab: styled(Tab)<{ selected: boolean }>`
    color: ${(p) =>
      p.selected
        ? p.theme.colors.midnight
        : p.theme.colors.battleshipGrey} !important;
    padding: 6px 14px;
    &:first-child {
      padding-right: 24px;
    }
  `,
  ProgressBarContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    right: 30px;
    bottom: calc(50% - 150px);
    height: 300px;
    z-index: 3;
  `,
  ProgressBarStepContainer: styled.ul`
    display: flex;
    flex-direction: row;
    ${(p) => p.theme.breakpoints.up("md")} {
      align-items: center;
    }
  `,
  ProgressBarStep: styled.li<{
    $activeStep: boolean
    $activeColor: string
    $inactiveColor: string
  }>`
    width: 2px;
    height: 22px;
    background-color: ${(p) =>
      p.$activeStep ? p.$activeColor : p.$inactiveColor};
  `,
  ProgressBarText: styled(Typography)<{
    $visible: boolean
    $activeColor: string
  }>`
    display: ${(p) => (p.$visible ? "block" : "none")};
    font-size: 12px;
    margin-right: 10px;
    color: ${(p) => (p.$visible ? p.$activeColor : "")};
  `,
}

export interface StaticMenuSection {
  id: string
  title: string
}

const StaticMenu = () => {
  const { selected, sections } = useScrollSections()
  const { setSelectedScrollSection } = useLayoutContext()
  const numberOfSections = Array.from(Array(sections.length).keys())

  useEffect(() => {
    setSelectedScrollSection(selected)
    trackVehiclePageView({ tabName: selected })
  }, [selected])

  return (
    <>
      <Styled.StaticMenu
        scrollButtons="off"
        value={selected}
        variant="scrollable"
      >
        {sections.map((item, i) => (
          <Styled.Tab
            key={i}
            onClick={() => item.onClick()}
            selected={item.selected}
            label={item.title}
            value={item.id}
          />
        ))}
      </Styled.StaticMenu>

      <Desktop>
        <Styled.ProgressBarContainer role="navigation">
          {numberOfSections.map((index) => {
            const isActive =
              index === sections.findIndex((el) => el.id === selected)
            return (
              <Styled.ProgressBarStepContainer
                aria-current={isActive ? "step" : null}
                key={index}
              >
                <Styled.ProgressBarStep
                  $activeStep={isActive}
                  $activeColor={sections[index].activeColor || ""}
                  $inactiveColor={sections[index].inactiveColor || ""}
                />
                <Styled.ProgressBarText
                  $visible={isActive}
                  $activeColor={sections[index].activeColor || ""}
                >
                  {sections[index].title}
                </Styled.ProgressBarText>
              </Styled.ProgressBarStepContainer>
            )
          })}
        </Styled.ProgressBarContainer>
      </Desktop>
    </>
  )
}

export default StaticMenu
